<script lang="ts">
  import classnames from 'classnames';
  import { createEventDispatcher } from 'svelte';
  // Assets
  import facebookLogo from '$lib/assets/social/facebook.svg';
  import googleLogo from '$lib/assets/social/google.svg';

  export let social: 'google' | 'facebook' = 'google';
  export let url = '#';

  const dispatch = createEventDispatcher();

  const onClick = () => {
    dispatch('click');
  };

  const logoAssets = [
    { name: 'facebook', component: facebookLogo },
    { name: 'google', component: googleLogo },
  ];

  $: asset = logoAssets.filter((item) => item.name === social);

  const classNames = classnames($$props.class, {
    'social-login-button': true,
    'social-google': social === 'google',
    'social-facebook': social === 'facebook',
  });
</script>

<a href={url} class={classNames} on:click|preventDefault={onClick}>
  <div class="social-login-icon-container">
    <span class="social-login-icon">
      <img src={asset[0].component} alt={asset[0].name} />
    </span>
  </div>
  <span class="social-login-text"><slot /></span>
</a>

<style type="text/scss" lang="scss" scoped>
  :global(svg) {
    width: 20px;
    height: 20px;
  }

  .social-login-button {
    --social-btn-height: theme('spacing.12'); /* 48px */
    @apply inline-flex flex-nowrap flex-shrink-0 items-center;
    @apply w-full;
    @apply relative;
    @apply rounded-md;
    height: var(--social-btn-height);
  }

  .social-login-text {
    @apply text-base font-sans font-normal;
    @apply whitespace-nowrap;
    @apply px-3;
  }

  .social-login-icon {
    @apply w-5 h-5;
    @apply inline-flex mx-3;
  }

  .social-login-icon-container {
    @apply inline-flex items-center justify-center self-stretch;
    @apply border-r;
  }

  /* GOOGLE */
  .social-login-button.social-google {
    @apply text-gray-900 bg-white;
    @apply border border-gray-300;

    .social-login-icon-container {
      @apply border-gray-300 border-opacity-50;
    }
  }

  /* FACEBOOK */

  .social-login-button.social-facebook {
    @apply text-gray-900 bg-white;
    @apply border border-gray-300;

    .social-login-icon-container {
      @apply border-gray-300 border-opacity-50;
    }
  }
</style>
