<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  // Assets
  import emailLogo from '$lib/assets/original/email.svg';

  const dispatch = createEventDispatcher();
  export let url = '/';

  const onClick = () => {
    dispatch('click');
  };

</script>

<a href={url} class="email-login-button" on:click|preventDefault={onClick}>
  <div class="email-login-icon-container">
    <span class="email-login-icon">
      <img src={emailLogo} alt="Email" />
    </span>
  </div>
  <span class="email-login-text"><slot /></span>
</a>


<style type="text/scss" lang="scss" scoped>
  :global(svg) {
    width: 20px;
    height: 20px;
  }

  .email-login-button {
    --social-btn-height: theme('spacing.12'); /* 48px */
    @apply inline-flex flex-nowrap flex-shrink-0 items-center;
    @apply w-full;
    @apply relative;
    @apply rounded-md;

    @apply border border-transparent;
    @apply bg-brand border-brand text-gray-900;

    /* Hover */
    @apply transition-colors hover:bg-brand-dark hover:border-brand-dark;
    /* Focus */
    @apply focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-brand focus:ring-opacity-30;
    height: var(--social-btn-height);
    .email-login-icon-container {
      @apply border-white border-opacity-50;
    }
  }

  .email-login-text {
    @apply text-base font-sans font-normal;
    @apply whitespace-nowrap;
    @apply px-3;
  }

  .email-login-icon {
    @apply w-5 h-5;
    @apply inline-flex mx-3;
  }

  .email-login-icon-container {
    @apply inline-flex items-center justify-center self-stretch;
    @apply border-r;
  }
</style>
