<script lang="ts">
	import { Button, SocialLoginButton, EmailLoginButton } from '$lib/components/atoms/Button';
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import { useOktaStore } from '$lib/utils/auth-store';
	import { useCTAFlow } from '$lib/utils/cta-flow';
	import { GtmStore } from '$lib/utils/gtm-store';
	import classNames from 'classnames';
	import { createEventDispatcher } from 'svelte';
	import { get } from 'svelte/store';
	import { PUBLIC_OKTA_GOOGLE_IDP_ID, PUBLIC_OKTA_FACEBOOK_IDP_ID } from '$env/static/public';
	import { browser } from '$app/environment';

	const dispatch = createEventDispatcher();

	const authStore = useOktaStore();
	const ctaFlow = useCTAFlow();

	export let socialAction = 'Registrati';
	export let socialInline: boolean = false;
	export let useDecoratedTitle: boolean = false;
	export let showTitle = true;
	export let noRedirect: boolean = false;
	export let isRegistering: boolean = false;
	export let isRegistrationPage: boolean = true;
	export let options: {
		socialSection?: string | boolean;
		mainSection?: string | boolean;
		loginSection?: string | boolean;
		socialOnly?: string;
		selectedEmail?: string;
	};

	export let ctaLoading = false;

	const pushRegistrationLoginGtm = (registrationType: string) => {
		/******************** GTM EVENT PUSH *************************/
		GtmStore.pushEvent(
			{
				event: 'BBevent',
				category: isRegistrationPage ? 'Registration' : 'Login',
				action: 'OK',
				label: registrationType ? registrationType : ' ',
				event_id: isRegistrationPage ? '3' : '10'
			},
			false
		);
		/*************************************************************/
	};

	const handleSocialSignIn = () => {
		const userInfo = get(authStore.userInfo);

		const callback = browser ? ($page.url.searchParams.get('callback') as string) : '';
		const cta = browser ? ($page.url.searchParams.get('cta') as string) : '';

		dispatch('social-sign-in', userInfo);

		if (ctaFlow.shouldGoToCompletionForm(true)) {
			goto(
				'registrati/completion' + (callback ? `?callback=${callback}` : cta ? `?cta=${cta}` : ''),
				{
					replaceState: true
				}
			);

			if (noRedirect) return;
		} else if (callback) {
			goto(callback, { replaceState: true });
		} else if (cta && ctaFlow.actionInPlace) {
			ctaLoading = true;
			ctaFlow.continueCTAFlow().catch(() => (ctaLoading = false));
		} else {
			goto('/');
		}
	};

	/**
	 * Perform the social sign in
	 * @param idp The IDP unique code
	 * @param redirect True to redirect, false to use popup
	 */
	const socialSignIn = (
		social: 'google' | 'facebook',
		idp: string,
		redirect: boolean,
		errorMessage?: string
	) => {
		const callback = browser ? ($page.url.searchParams.get('callback') as string) : '';
		const cta = browser ? ($page.url.searchParams.get('cta') as string) : '';
		isRegistering = true;
		authStore
			.signInWithIdp(idp, redirect, callback, cta, true)
			.then(() => {
				pushRegistrationLoginGtm(social);
				handleSocialSignIn();
			})
			.catch((e) => {
				dispatch('social-auth-error', { e, errorMessage, redirect });
			})
			.finally(() => {
				isRegistering = false;
			});
	};

	const onSocialLogin = (type: 'google' | 'facebook') => {
		const idp = {
			google: PUBLIC_OKTA_GOOGLE_IDP_ID,
			facebook: PUBLIC_OKTA_FACEBOOK_IDP_ID
		};

		socialSignIn(
			type,
			idp[type],
			false,
			'Utilizza la registrazione tradizionale oppure accedi nuovamente tramite la registrazione social'
		);
	};

	const onClick = (type: 'google' | 'facebook' | 'register' | 'login') => {
		const searchParams = new URLSearchParams(window.location.search);
		const callback = searchParams.get('callback');
		const cta = searchParams.get('cta');

		switch (type) {
			case 'register':
				goto('registrati/form' + (callback ? `?callback=${callback}` : cta ? `?cta=${cta}` : ''), {
					replaceState: true
				});
				break;
			case 'login':
				goto('/login' + (callback ? `?callback=${callback}` : cta ? `?cta=${cta}` : ''), {
					replaceState: true
				});
				break;
			case 'google':
				onSocialLogin('google');
				break;
			case 'facebook':
				onSocialLogin('facebook');
				break;
		}
	};
</script>

<div class="container">
	{#if options.socialSection}
		{#if showTitle}
			{#if useDecoratedTitle}
				<h3 class="decorated"><span>{options.socialSection}</span></h3>
			{:else}
				<h1 class="fs-2 md:fs-4">{options.socialSection}</h1>
			{/if}
		{/if}

		<div
			class={classNames('social-button-container', {
				'text-center': useDecoratedTitle,
				'social-inline': socialInline,
				'mt-8 mb-3': options.socialOnly,
				'my-8': !options.socialOnly
			})}
		>
			{#if options.socialOnly && options.socialOnly == 'google'}
				<SocialLoginButton social="google" on:click={() => onClick('google')}>
					<div class="flex flex-col">
						{socialAction} con Google
						{#if options.selectedEmail}<small class="leading-none">{options.selectedEmail}</small
							>{/if}
					</div>
				</SocialLoginButton>
			{:else if options.socialOnly && options.socialOnly == 'facebook'}
				<SocialLoginButton social="facebook" on:click={() => onClick('facebook')}>
					<div class="flex flex-col">
						{socialAction} con Facebook
						{#if options.selectedEmail}<small class="leading-none">{options.selectedEmail}</small
							>{/if}
					</div>
				</SocialLoginButton>
			{:else}
				<SocialLoginButton social="google" on:click={() => onClick('google')}
					>{socialAction} con Google</SocialLoginButton
				>
				<SocialLoginButton social="facebook" on:click={() => onClick('facebook')}
					>{socialAction} con Facebook</SocialLoginButton
				>
			{/if}
		</div>
	{/if}

	{#if options.mainSection}
		{#if useDecoratedTitle}
			<h3 class="decorated"><span>{options.mainSection}</span></h3>
		{:else}
			<h1 class="fs-2 md:fs-4">{options.mainSection}</h1>
		{/if}

		<div
			class={classNames('my-8', 'email-button-container', {
				'text-center': useDecoratedTitle,
				'social-inline': socialInline
			})}
		>
			<EmailLoginButton on:click={() => onClick('register')}>
				Registrati con la tua mail
			</EmailLoginButton>
		</div>
	{/if}

	{#if options.loginSection}
		{#if useDecoratedTitle}
			<h3 class="decorated"><span>{options.loginSection}</span></h3>
		{:else}
			<h1 class="fs-2 md:fs-4">{options.loginSection}</h1>
		{/if}

		<div
			class={classNames('my-8', {
				'text-center': useDecoratedTitle
			})}
		>
			<Button on:click={() => onClick('login')}>Accedi</Button>
		</div>
	{/if}
</div>

<style type="text/css" lang="scss" scoped>
	.social-button-container,
	.email-button-container {
		@apply grid grid-cols-1 gap-2;

		&.social-inline {
			@apply grid grid-cols-1 md:grid-cols-2 gap-8;
		}
	}

	h3 {
		@apply text-lg;
	}
	/* headlines with lines */
	.decorated {
		@apply overflow-hidden;
		@apply text-center;
	}
	.decorated > span {
		@apply relative;
		@apply inline-block;
	}
	.decorated > span:before,
	.decorated > span:after {
		content: '';
		@apply absolute;
		@apply top-1/2;
		@apply border-b border-gray-300;
		@apply mx-0 my-5;
		width: 591px; /* half of limiter*/
	}
	.decorated > span:before {
		@apply right-full;
	}
	.decorated > span:after {
		@apply left-full;
	}
</style>
